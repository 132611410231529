import styled from 'styled-components';

import AboutMeCard from "../components/AboutMeCard";
import PhotoCard from "../components/PhotoCard";

import Page from "../design-system/Page";

const AboutMeSection = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  align-items: center;
  @media (max-width: 680px) {
    flex-direction: column;
    align-items: center;
  }
`

export default function PageLanding() {
  return (
    <Page>
      <AboutMeSection>
        <PhotoCard />
        <AboutMeCard />
      </AboutMeSection>
    </Page>
  );
}
