import styled from 'styled-components';

const Container = styled.div`
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`
interface Props {
  children: React.ReactNode,
}

export default function Page ({children}: Props): React.ReactElement {
  return (
    <Container>
        {children}
    </Container>
  )
}
