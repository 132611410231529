import aidenPhoto from '../assets/aiden-photo.jpg';
import facebook from '../assets/facebook.png';
import github from '../assets/github.png';
import linkedin from '../assets/linkedin.png';
import styled from 'styled-components';

const Card = styled.div`
  background-color: #f4ece6;
  border-radius: 4px;
  box-shadow: 0 7px 30px -10px rgba(0, 0, 0, 0.6);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 375px;
  flex-basis: 2;
`;
const Heading = styled.h1`
  font-size: 32px;
  &:after {
    content: ' ';
    display: block;
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    border: 1px solid black;
  }
`;
const Image = styled.img`
  border-radius: 50%;
  overflow: hidden;
  max-width: 50%;
  margin-top: 32px;
`;
const PersonalsContainer = styled.div`
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  height: 48px;
  margin-top: auto;
  align-items: center;
`;

const PERSONALS: IconProps[] = [
  {
    alt: 'facebook link',
    link: 'https://facebook.com/aidenywl',
    imageSrc: facebook,
  },
  {
    alt: 'linkedin link',
    link: 'https://linkedin.com/in/aidenywl',
    imageSrc: linkedin,
  },
  {
    alt: 'github link',
    link: 'https://github.com/aidenywl',
    imageSrc: github,
  },
];
export default function Photocard(): React.ReactElement {
  return (
    <Card>
      <Image alt="aiden profile" src={aidenPhoto} />
      <Heading>Aiden Low</Heading>
      <PersonalsContainer>
        {PERSONALS.map((personalData) => (
          <Icon {...personalData} />
        ))}
      </PersonalsContainer>
    </Card>
  );
}

const Anchor = styled.a`
  padding: 0 16px;
  &:hover {
    opacity: 0.6;
  }
`;
const IconImage = styled.img`
  height: 20px;
  width: auto;
`;

interface IconProps {
  alt: string;
  link: string;
  imageSrc: string;
}

function Icon({ alt, link, imageSrc }: IconProps): React.ReactElement {
  return (
    <Anchor target="_blank" href={link} rel="noreferrer">
      <IconImage alt={alt} src={imageSrc} />
    </Anchor>
  );
}
