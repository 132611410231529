import styled from 'styled-components';

import { useState, useEffect } from "react";

const Container = styled.div`
  position: relative;
  display: inline-block;
`
const Cursor = styled.div`
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  right: -5px;
  width: 2px;
  background-color: #000000;
  z-index: 1;
  animation: flash 0.5s none infinite alternate;
  @keyframes flash{
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
const Span = styled.span`
  color: #0C65C2;
`

export interface Props {
    children: string;
}

export default function Home({ children }: Props): React.ReactElement {
  const [index, setIndex] = useState(0);
  const [initialDelay, setInitialDelay] = useState(550);
  useEffect(() => {
    if (index === 1) {
      setInitialDelay(0);
    }
    if (index === children.length + 1) {
      return;
    }
    const timeout = setTimeout(() => {
      setIndex((prev) => prev + 1);
    }, 200 + initialDelay);
    return () => clearTimeout(timeout);
  }, [children, initialDelay, index]);
  return (
    <Container>
      <Cursor />
      <Span>
        {`${children.substring(0, index)}`}
      </Span>
    </Container>
  );
}
